<template>
    <v-container>
        <!-- calendar -->
        <v-row>
            <!-- date from -->
            <v-col cols="12" sm="6">
                <v-menu ref="menuDate"
                        v-model="menuDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        z-index="1000">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateFormatted"
                                      label="Date from"
                                      hint="DD.MM.YYYY format"
                                      persistent-hint
                                      prepend-icon="mdi-calendar"
                                      v-bind="attrs"
                                      @blur="dateFrom = parseDateFromNorthEuropeToISO(dateFormatted)"
                                      v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dateFrom"
                                   no-title
                                   :events="datesEvents"
                                   event-color="blue lighten-2"
                                   @input="menuDate = false"
                                   color="blue lighten-2"></v-date-picker>
                </v-menu>
            </v-col>
            <!-- time from -->
            <v-col cols="12" sm="6">
                <v-menu ref="menuTime"
                        v-model="menuTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="timeFrom"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        z-index="1000">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="timeFrom"
                                      label="Time from"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="menuTime"
                                   v-model="timeFrom"
                                   full-width format="24hr" color="blue lighten-2"
                                   @click:minute="$refs.menuTime.save(timeFrom)"></v-time-picker>
                </v-menu>
            </v-col>
            <!-- date to -->
            <v-col cols="12" sm="6">
                <v-menu ref="menuDateTo"
                        v-model="menuDateTo"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        z-index="1000">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateFormattedTo"
                                      label="Date to"
                                      hint="DD.MM.YYYY format"
                                      persistent-hint
                                      prepend-icon="mdi-calendar"
                                      v-bind="attrs"
                                      @blur="dateTo = parseDateFromNorthEuropeToISO(dateFormattedTo)"
                                      v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dateTo"
                                   no-title
                                   :events="datesEvents"
                                   event-color="blue lighten-2"
                                   @input="menuDateTo = false"
                                   color="blue lighten-2"></v-date-picker>
                </v-menu>
            </v-col>
            <!-- time to -->
            <v-col cols="12" sm="6">
                <v-menu ref="menuTimeTo"
                        v-model="menuTimeTo"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="timeTo"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        z-index="1000">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="timeTo"
                                      label="Time to"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="menuTimeTo"
                                   v-model="timeTo"
                                   full-width format="24hr" color="blue lighten-2"
                                   @click:minute="$refs.menuTimeTo.save(timeTo)"></v-time-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row class="text-center">
            <!-- get location by date -->
            <v-col class="text-center"
                   cols="12">
                <v-btn color="blue lighten-2"
                       dark
                       @click="getLocationsByDate()">
                    Get location by date
                </v-btn>
                <v-alert v-model="alertLocationByDate"
                         dismissible
                         shaped
                         text
                         type="info" class="ma-10">
                    {{ alertMessage }}
                </v-alert>
            </v-col>

            <v-col class="mb-5"
                   cols="12">

            </v-col>

            <v-col v-if="pointsData !== null" class="mb-4">

                <!-- map -->
                <l-map style="height: 400px" :zoom="zoom" :center="center">
                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                    <l-marker v-for="item in pointsData" :key="item.id" :lat-lng="mergeCoordToArray(item.lat, item.lon)">
                        <l-popup>
                            <v-list dense>
                                <v-list-item-group color="primary">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon v-text="mdiaccount" color="blue lighten-2"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.id"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon v-text="mdiclock" color="blue lighten-2"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.timestamp"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </l-popup>
                    </l-marker>
                </l-map>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>

    // init
    const isDevMode = process.env.NODE_ENV == 'development';
    const baseURL = isDevMode ? 'http://192.168.0.103:5001' : 'https://vladtsiukin.somee.com';
    const _days = 1;
    const _dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000));
    const _dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000));   
    _dateTo.setTime(_dateTo.getTime() + (_days * 24 * 60 * 60 * 1000));

    export default {
        name: 'HelloWorld',

        data: vm => ({
            // map
            zoom: 11,
            center: [53.893009, 27.567444],
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            showParagraph: false,
            mapOptions: {
                zoomSnap: 0.5,
            },
            showMap: true,
            // data
            pointsData: null,
            // popup
            mdiclock: 'mdi-clock',
            mdiaccount: 'mdi-account',
            // calendar
            dateFrom: _dateFrom.toISOString().substr(0, 10),
            dateTo: _dateTo.toISOString().substr(0, 10),
            dateFormatted: vm.formatDateNorthEurope(_dateFrom.toISOString().substr(0, 10)),
            dateFormattedTo: vm.formatDateNorthEurope(_dateTo.toISOString().substr(0, 10)),
            datesEvents: null,
            menuDate: false,
            menuDateTo: false,
            menuTime: null,
            menuTimeTo: null,
            timeFrom: '00:00',
            timeTo: '00:00',
            // dialog
            dialog: false,
            // alerts
            alertLocationByDate: false,
            alertMessage: '',
        }),

        created() {

        },

        mounted() {
            this.getAllLocationDates();
        },

        computed: {

        },

        methods: {
            async getAllLocations() {
                try {
                    this.pointsData = null;

                    const res = await fetch(`${baseURL}/api/locations`);

                    if (!res.ok) {
                        const message = `An error has occured: ${res.status} - ${res.statusText}`;
                        throw new Error(message);
                    }

                    this.pointsData = await res.json();

                } catch (err) {
                    console.error(err);
                    this.pointsData = null;
                }
            },
            async getLocationsByDate() {
                try {
                    this.$emit("listenerToProgress", true);
                    this.pointsData = null;

                    const from = this.dateFrom + 'T' + this.timeFrom;
                    const to = this.dateTo + 'T' + this.timeTo;

                    const res = await fetch(`${baseURL}/api/locations/dates/1/${from}/${to}`);

                    if (!res.ok) {
                        const message = `An error has occured: ${res.status} - ${res.statusText}`;
                        throw new Error(message);
                    }

                    this.pointsData = await res.json();

                    if (this.pointsData.length === 0) {
                        this.pointsData = null;
                        this.alertLocationByDate = true;
                        this.alertMessage = 'No Data';
                    }

                    this.$emit("listenerToProgress", false);

                } catch (err) {
                    console.error(err);
                    this.pointsData = null;
                    this.$emit("listenerToProgress", false);
                }
            },
            async getAllLocationDates() {
                try {
                    this.datesEvents = null;

                    const res = await fetch(`${baseURL}/api/locations/dates/1`);

                    if (!res.ok) {
                        const message = `An error has occured: ${res.status} - ${res.statusText}`;
                        throw new Error(message);
                    }

                    const data = await res.json();
                    this.datesEvents = data.map((date) => {
                        const d = new Date(date)
                        return d.toISOString().substr(0, 10)
                    });

                } catch (err) {
                    console.error(err);
                    this.datesEvents = null;
                }
            },
            mergeCoordToArray(lat, lon) {
                return [lat, lon];
            },
            formatDateNorthEurope(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}.${month}.${year}`
            },
            parseDateFromNorthEuropeToISO(date) {
                if (!date) return null

                const [day, month, year] = date.split('.')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
        },

        watch: {
            dateFrom() {
                this.dateFormatted = this.formatDateNorthEurope(this.dateFrom);
            },
            dateTo() {
                this.dateFormattedTo = this.formatDateNorthEurope(this.dateTo);
            },
        },
    }
</script>

<style>
    .v-sheet.v-list,
    .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
        border-radius: .5rem;
    }

    .leaflet-popup-content-wrapper, .leaflet-popup-tip{
        background-color:transparent !important;
    }

    .v-dialog__content {
        z-index:1000 !important;
    }
</style>

<template>
    <v-app>
        <v-app-bar app
                   color="#121212"
                   dark>
            <div class="d-flex align-center">
                <v-img alt="Vuetify Logo"
                       class="shrink mr-2"
                       contain
                       src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
                       transition="scale-transition"
                       width="40" />

                <v-toolbar-title>Locator</v-toolbar-title>
            </div>

            <v-progress-linear :active="mainProgressIsLoading"
                               :indeterminate="mainProgressIsLoading"
                               absolute
                               bottom
                               color="blue lighten-2"></v-progress-linear>

            <v-spacer></v-spacer>

        </v-app-bar>

        <v-main>
            <!-- @click="$refs.location_c.getAllLocations() -->
            <HelloWorld ref="location_c" v-on:listenerToProgress="listenerToProgress" />
        </v-main>

        <v-footer padless>
            <v-col class="text-center"
                   cols="12">
                {{ new Date().getFullYear() }} &copy; <strong>Locator</strong>
            </v-col>
        </v-footer>
    </v-app>
</template>

<script>
    import HelloWorld from './components/HelloWorld';

    export default {
        name: 'App',

        components: {
            HelloWorld,
        },

        data: () => ({
            mainProgressIsLoading: false
        }),

        methods: {
            listenerToProgress(reply) {
                this.mainProgressIsLoading = reply;
            }
        },
    };
</script>
